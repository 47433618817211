@root-entry-name: 'default';
@import (reference) '~antd/es/style/themes/index.less';
@pro-layout-grid-content-prefix-cls: ~'@{ant-prefix}-pro-grid-content';

.@{pro-layout-grid-content-prefix-cls} {
  width: 100%;
  &.wide {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@primary-color: #5a4f0a;